#root {
  display: flex;
  justify-content: center;
}

body {
  font-family: "Dosis", sans-serif;
}

.App {
  background-color: rgb(254, 253, 251);
  height: 100dvh;
  color: rgb(57, 12, 43);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  width: 100%;
  margin: auto;
  padding-bottom: 80px;
  position: fixed;
}

.App-img {
  height: 160px;
}

.App-bigText {
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  padding-top: 16px;
}

.App-cta {
  margin-top: 24px;
  height: 40px;
  border-radius: 8px;
  border: none;
  font-family: "Dosis", sans-serif;
  box-shadow: none;
  padding: 16px 24px;
  font-size: 24px;
  font-weight: 800;
  background: rgb(234, 51, 179);
  color: rgb(57, 12, 43);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.App-cta:hover {
  opacity: 0.8;
}

.App-withBg {
  background:rgb(234, 51, 179);
  display: flex;
  align-items: center;
  margin-top: 24px;
  padding: 16px 24px;
  font-weight: 800;
}

.App-footer {
  display: flex;
  width: 100%;
  background: rgb(57, 12, 43);
  position: fixed;
  bottom: 0;
  height: 48px;
  cursor: pointer;
}

.App-footer:hover {
  opacity: 0.8;
}

.App-footer-progress {
  display: flex;
  width: 0%;
  transition: width ease-in-out 300ms;
  color: rgb(57, 12, 43);
  background:rgb(234, 51, 179);
  position: fixed;
  bottom: 0;
  height: 48px;
}

.App-footer-step {
  display: flex;
  color: rgb(254, 253, 251);
  position: fixed;
  bottom: 0;
  height: 48px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.App-footer-step-final {
  font-weight: 800;
}

.App-die-rolling {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 200px;
  width: 200px;
}

.App-die-rolling img {
  width: 50%;
}

.App-die-rolling img.spinning {
  animation: rollDie 300ms linear infinite;
}

@keyframes rollDie {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-resultsPage {
  height: 100dvh;
  width: 100%;
  background:rgb(234, 51, 179);
  position: fixed;
  top: 0;
  animation: slideIn 300ms linear forwards;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

.App-resultSection {
  border-radius: 8px;
  background-color: rgb(57, 12, 43);
  color: rgb(254, 253, 251);
  width: 80%;
  margin-bottom: 24px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App-resultHeader {
  font-size: 18px;
  font-weight: 800;
}

.App-resultValue {
  font-size: 16px;
  font-weight: 500;
}

.App-beat {
  width: 100%;
  margin-bottom: 24px;
}

.App-beat-input {
  width: 100%;
  display: flex;
  gap: 16px;
}

.App-beat-label {
  margin-bottom: 16px;
}

.App-beat-input input {
  flex: 1;
}

.slider {
  -webkit-appearance: none; /* Remove default styling */
  appearance: none;
  width: 100%;
  height: 8px;
  background:rgb(234, 51, 179);
  outline: none;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Remove default styling */
  appearance: none;
  width: 40px; /* Increase thumb size for better tap target */
  height: 40px; /* Increase thumb size for better tap target */
  background: rgb(254, 253, 251);
  cursor: pointer;
  border-radius: 50%; /* Make the thumb circular */
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5); /* Add shadow for better visibility */
}

.slider::-moz-range-thumb {
  width: 40px; /* Increase thumb size for better tap target */
  height: 40px; /* Increase thumb size for better tap target */
  background: rgb(254, 253, 251);
  cursor: pointer;
  border-radius: 50%; /* Make the thumb circular */
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5); /* Add shadow for better visibility */
}

.App-resultsCta {
  background: rgb(254, 253, 251);
  margin-bottom: 40px;
}

.fatArrow {
  width: 80px;
  height: 80px;
  fill:rgb(234, 51, 179);
  rotate: 90deg;
  animation: bounce 1s linear infinite;
  position: absolute;
  bottom: 200px
}

@keyframes bounce {
  0% {
    transform: translateX(0%);
  }
  25% {
    transform: translateX(50%);
  }
  50% {
    transform: translateX(0%);
  }
  75% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}

.content {
  width: 100%;
}

.validation-message {
  font-size: 24px;
  font-weight: 800;
  margin-top: 24px;
  text-align: center;
  margin-bottom: 16px;
}

.chord-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
}

.single-chord {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
}
.timesig {
  color:rgb(234, 51, 179);
}